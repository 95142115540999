import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import eventos from "../../data/a-escola/desafios-e-festivais/calendario.json";
import SEO from "../../components/SEO/SEO";

export default function DesafiosEFestivais() {
	let events = [];

	eventos.forEach((evento) => {
		//Ajuste nas datas para representar no calendario da mesma forma que a cadastrada
		let dataFim;
		let color;
		if (evento.DataInicio !== evento.DataFim) {
			dataFim = new Date(evento.DataFim);
			dataFim.setDate(dataFim.getDate() + 1);
		} else {
			dataFim = Date.parse(evento.DataFim);
		}

		color = "#01afc0";
		if (evento.NomeEvento.toLowerCase().includes("não letivo") || evento.NomeEvento.toLowerCase().includes("feriado")) {
			color = "#adc90c";
		} 
		
		if (evento.NomeEvento.toLowerCase().includes("formatura") || evento.NomeEvento.toLowerCase().includes("volta dos")) {
			color = "#fa15a6";
		}

		events.push({
			title: evento.NomeEvento,
			allDay: true,
			start: Date.parse(evento.DataInicio),
			end: dataFim,
			backgroundColor: color,
			className: ['m-1']
		});
	});

	return (
		<Layout>
			<SEO title="Desafios e festivais" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<h1 className="pageTitle fgprimary-gradient pt-5 pb-3">
							calendário (desafios e festivais)
						</h1>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="pt-1">
							// acompanhe tudo o que os nossos estudantes farão ao longo do
							ano, entre atividades, comemorações, formaturas e muito mais.
							Saiba também quando serão os feriados.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<FullCalendar
							plugins={[dayGridPlugin]}
							headerToolbar={{
								left: "",
								center: "title",
								right: "prev,next",
							}}
							initialView="dayGridMonth"
							locale="pt-br"
							events={{ events }}
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgprimary-gradient mt-4 text-left">
							dias letivos 2024
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={4}>
						<ul style={{ listStyleType: "none" }} className="mb-0">
							<li>
								<b>// fevereiro</b>: 11 dias letivos
							</li>
							<li>
								<b>// março</b>: 21 dias letivos
							</li>
							<li>
								<b>// abril</b>: 22 dias letivos
							</li>
							<li>
								<b>// maio</b>: 20 dias letivos
							</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={4}>
						<ul style={{ listStyleType: "none" }} className="mb-0">
							<li>
								<b>// junho</b>: 21 dias letivos
							</li>
							<li>
								<b>// julho</b>: 13 dias letivos
							</li>
							<li>
								<b>// agosto</b>: 23 dias letivos
							</li>
							<li>
								<b>// setembro</b>: 21 dias letivos
							</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={4}>
						<ul style={{ listStyleType: "none" }} className="mb-0">
							<li>
								<b>// outubro</b>: 23 dias letivos
							</li>
							<li>
								<b>// novembro</b>: 20 dias letivos
							</li>
							<li>
								<b>// dezembro</b>: 06 dias letivos
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
